














import { Vue, Component } from "vue-property-decorator";
import { Observer } from "mobx-vue";

@Observer
@Component
export default class Timeline extends Vue {}
